import React from "react";


function item(props) {
  return (
    <div class="frame">
      {props.data.map((data, i) =>
        <a key={i} href={data.url} onClick={(e) => { e.preventDefault(); window.open(data.url) }} class="custom-btn btn-service" >
          <img class="serviceImg" src={data.logo} alt={` ${data.ssoName} Analytics`} />
        </a>
      )}
    </div>
  );
}
export default item;