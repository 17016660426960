export const data = [
  {
    title: "Data Processing",
    description: "Helps in increasing the productivity, accurate report making and profits.",
    logo: "images/card1.png",
    backgroundColor: "#fdc0bf",
  },
  {
    title: "Statistics",
    description: "Improves the application analytics reporting and makes it more precise",
    logo: "images/card6.png",
    backgroundColor: "#aefaed",
  },
  {
    title: "Usages",
    description: "Usage pattern helping in Scale your service and handle peak load",
    logo: "images/card7.png",
    backgroundColor: "#a6c3fb",
  },
  {
    title: "User Activities",
    description: "Understand user behaviour with respect to your product/service.",
    logo: "images/card4.png",
    backgroundColor: "#fcf7b3",
  },
  {
    title: "Service Enhancement",
    description: "Understand service usage pattern and Invest accordingly",
    logo: "images/card3.png",
    backgroundColor: "#aff3fc",
  },
  {
    title: "Real-time Audit",
    description: "Helps discover application bugs/error realtime.",
    logo: "images/card5.png",
    backgroundColor: "#c8fdc9",
  },
  {
    title: "Hack Mitigation",
    description: "Secures your account with realtime event analysis for hacking attempts.",
    logo: "images/card2.png",
    backgroundColor: "#c996fd",
  },
];

export const sliderData = [
  {
    logo: "images/parichay-logo.png",
    ssoName: 'Parichay',
    url: 'https://parichay.analytics.gov.in/login/',
    alt: `https://via.placeholder.com/184x58?text=Parichay+Analytics`
  },
  {
    logo: "images/pehchan-logo.png",
    ssoName: 'Pehchan',
    url: 'https://pehchan.analytics.gov.in/login/',
    alt: `https://via.placeholder.com/184x58?text=Visit+Pehchaan+Analytics+Now`
  },
  {
    logo: "images/pehchaan-logo-blue.png",
    ssoName: 'JanParichay',
    url: 'https://janparichay.analytics.gov.in/login/',
    alt: `https://via.placeholder.com/184x58?text=Visit+Pehchaan+Analytics+Now`
  },
  {
    logo: "images/ntp-logo.png",
    ssoName: 'NTP',
    url: 'https://ntp.analytics.gov.in/login/',
    alt: `https://via.placeholder.com/184x58?text=Visit+NTP+Analytics+Now`
  },
  // {
  //   logo: "images/GovDriveLogo.png",
  //   ssoName: 'GovDrive',
  //   url: 'https://drive.analytics.gov.in/login/',
  //   alt: `https://via.placeholder.com/184x58?text=Visit+GovDrive+Analytics+Now`
  // },
  // {
  //   logo: "images/authenticator-logo.png",
  //   ssoName: 'Parichay Authenticator',
  //   url: 'https://parichay.analytics.gov.in/login/',
  // alt: `https://via.placeholder.com/184x58?text=Visit+Parichay+Authenticator+Analytics+Now`
  // },
]

export const insightSliderData = [
  {
    logo: "images/insigntsLogo.png",
    ssoName: 'Parichay',
    url: 'https://insight.analytics.gov.in/parichay/login/',
    alt: `https://via.placeholder.com/184x58?text=Parichay+Insight`
  },
  {
    logo: "images/securityLogo.png",
    ssoName: 'Parichay',
    url: 'https://security.analytics.gov.in/parichay/login/',
    alt: `https://via.placeholder.com/184x58?text=Parichay+Security+Insight`
  }
]


