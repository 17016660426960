import React from 'react';

function CustomCard({ data }) {
  return (
    <div className='Card col px-1 d-flex' >
      <div className='card-image'>
        <img src={data.logo} alt='logo' />
      </div>
      <div className='cardContent w-100 p-2 pt-5' style={{ background: data.backgroundColor }}>
        <div className='cardHeading'>
          <h6 style={{ fontSize: "1.1em" }}>{data.title}</h6>
        </div>
        <div className='cardText'>
          <p style={{ fontSize: "0.9em" }}>{data.description}</p>
        </div>
      </div>
    </div>
  )
}
export default CustomCard;
