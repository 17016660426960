import React from 'react';
import './App.css';
import CustomCard from './component/CustomCard';
import { data } from "./data";
import ExampleSlider from './component/Slider';
import { sliderData, insightSliderData } from './data';

function App() {
  return (
    <div className="App">
      <div id="getStarted" className='row w-100 mx-auto'>
        <div className='headerBar row mx-auto p-4 pt-0'>
          <div className="col">
            <div className='headerLogo'>
              <img src={"images/new-analytics.png"} alt="Logo" href="" style={{ maxWidth: "165px" }} className="Img-fluid mainlogo" />
            </div>
          </div>
          <div className="col LogInBtn" style={{ alignSelf: 'center' }}>
            <a href="#started"><button className="custom-btn btn-service m-0 loginBtn">LogIn</button></a>
          </div>
        </div>
        <div className='col d-flex flex-column'>
          <div className="" style={{ height: '120px' }}>
          </div>
          <div className='serviceContent my-auto p-4 text-white'>
            <div className='serviceHeading'>
              <h2 className='mb-4' style={{ fontSize: '1.7em' }}>About Us</h2>
            </div>
            <div className='serviceText'>
              <p style={{ fontSize: '1em' }}>Analytics helps organizations discover, interpret, and communicate with meaningful patterns in data.
                It provides a competent platform and comprehensive services to audit account activities of Parichay/Jan Parichay
                and the integrated services in real-time. The Robust Supervision, Pattern Generation, and Extensive Security offered
                by Parichay Analytics help organizations in monitoring and controlling the various user activities.</p>
            </div>
          </div>
        </div>
        <div className='col p-0 right-img d-flex'>
          <img src={"images/right-img.png"} alt="" href="" style={{ width: "100%" }} className="Img-fluid" />
        </div>
        <div id="footer" className='row w-100 mx-auto'>
          {data.map((item, i) =>
            <CustomCard data={item} key={i} />
          )}
        </div>
      </div>
      <div className='row w-100 mx-auto p-3 services-container'>
        <div id="started" className='col p-5 pb-2'>
          <h2 className="text-center mb-30 text-white">Analytics Offered For Various Services</h2>
          <ExampleSlider data={sliderData} />
        </div>

      </div>
      <div className='row w-100 mx-auto p-3 services-container'>
        <div id="started" className='col p-5 pt-1 '>
          <h2 className="text-center mb-30 text-white">Insights Offered For Various Services</h2>
          <ExampleSlider data={insightSliderData} />
        </div>

      </div>
    </div>
  );
}

export default App;